import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, arrayDiffByKey } from "../lib/helpers";
import UpcomingNodes from "../components/asof-upcoming";
import PastNodes from "../components/asof-grid";
import IntroAsof from "../components/asof-intro";

export const query = graphql`
  query AsofPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      socialImage {
        asset {
          url
        }
      }
    }
    upcoming: allSanityAsofEntry(filter: { isFuture: { eq: true } }) {
      edges {
        node {
          ...SanityAsofLinkFragment
        }
      }
    }
    past: allSanityAsofEntry(filter: { isFuture: { eq: false } }) {
      edges {
        node {
          ...SanityAsofLinkFragment
        }
      }
    }
  }
`;

const AsofPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const asofSettings = data.site.asofSettings;
  const upcomingNodes =
    data && data.upcoming && mapEdgesToNodes(data.upcoming).filter(filterOutDocsWithoutSlugs);
  const pastNodes =
    data && data.past && mapEdgesToNodes(data.past).filter(filterOutDocsWithoutSlugs);

  return (
    <Layout className="asof">
      <SEO title="A State of Feeling" ogImage={site?.socialImage?.asset?.url}/>
      <Container>
        <IntroAsof {...asofSettings} />
        {
          (asofSettings.displayUpcoming = true && upcomingNodes && upcomingNodes.length > 0 && (
            <UpcomingNodes nodes={upcomingNodes} />
          ))
        }
        {pastNodes && pastNodes.length > 0 && <PastNodes nodes={pastNodes} />}
      </Container>
    </Layout>
  );
};

export default AsofPage;
