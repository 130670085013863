import React from "react";
import Asset from "./asset";
import styles from "./asof-upcoming.module.scss";
import theme from "../styles/theme.module.scss";
import Icon from "./icon";
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

function Upcoming(props) {
  const multiple = props.nodes.length > 1 ? true : false;

  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.upcoming} ${theme.mT7}`}>
        {multiple && (
          <>
            <div className={`${styles.swiperNav} ${theme.mB7}`}>
              <div className={`swiperPrev ${styles.swiperPrev}`}>
                <Icon symbol="arrowLeft" />
              </div>
              <div className={`swiperNext ${styles.swiperNext}`}>
                <Icon symbol="arrowRight" />
              </div>
            </div>

            <Swiper
              className={`${theme.swiperWrapper}`}
              navigation={{
                prevEl: ".swiperPrev",
                nextEl: ".swiperNext",
              }}
              spaceBetween={0}
              slidesPerView="auto"
              centeredSlides={true}
              loop={true}
            >
              {props.nodes &&
                props.nodes.map((node, index) => (
                  <SwiperSlide key={index} className={`${styles.swiperSlide} ${theme.swiperSlide}`}>
                    <div className={`${styles.inner} ${theme.pT10} ${theme.pB10}`}>
                      <div
                        className={`${theme.fdcont} ${theme.borderBottom}  ${theme.mB12} ${theme.pB12}`}
                      >
                        <span className={`${styles.label} ${theme.h5_founders}`}>Upcoming</span>
                        <span>{node.date}</span>
                      </div>

                      <h2 className={`${styles.title} ${theme.h5_founders} ${theme.mB5}`}>
                        {node.title}
                      </h2>
                      <div className={`${styles.content} ${theme.fdcont}`}>
                        <div className={`${styles.copy} ${theme.fd4}`}>
                          <p>{node.previewCopy}</p>
                          {node.url && (
                            <>
                              <a className={`${theme.h5_founders}`} href={node.url}>
                                Sign Up
                              </a>
                            </>
                          )}
                        </div>

                        <div
                          className={`${theme.fd4} ${theme.fdcont} ${
                            node.previewImages.length > 1 ? "" : theme.fdflush
                          }`}
                        >
                          {node.previewImages &&
                            node.previewImages.map((node, index) => (
                              <div key={index} className={`${styles.entry} ${theme.fd2} `}>
                                <Asset asset={node} maxWidth="800" />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </>
        )}
        {!multiple && (
          <>
            <div className={`${styles.swiperSlide} ${styles.lonely}`}>
              <div className={`${styles.inner} ${theme.pT10} ${theme.pB10}`}>
                <span
                  className={`${theme.borderBottom} ${styles.label} ${theme.h5_founders} ${theme.mB12} ${theme.pB12}`}
                >
                  Upcoming
                </span>
                <h2 className={`${styles.title} ${theme.h5_founders} ${theme.mB5}`}>
                  {props.nodes[0].title}
                </h2>
                <div className={`${styles.content} ${theme.fdcont}`}>
                  <div className={`${styles.copy} ${theme.fd4}`}>
                    <p>{props.nodes[0].previewCopy}</p>
                    {props.nodes[0].url && (
                      <>
                        <a className={`${theme.h5_founders}`} href={props.nodes[0].url}>
                          Sign Up
                        </a>
                      </>
                    )}
                  </div>

                  <div
                    className={`${theme.fd4} ${theme.fdcont} ${
                      props.nodes[0].previewImages.length > 1 ? "" : theme.fdflush
                    }`}
                  >
                    {props.nodes[0].previewImages &&
                      props.nodes[0].previewImages.map((node, index) => (
                        <div key={index} className={`${styles.entry} ${theme.fd2} `}>
                          <Asset asset={node} maxWidth="800" />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Upcoming;
