import React, { Component } from "react";
import Link from "./link";
import Icon from "./icon";
import styles from "./home-intro.module.scss";
import theme from "../styles/theme.module.scss";

const textArray = ["beautiful", "meaningful", "responsible"];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { textIdx: 0 };
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let textThatChanges = textArray[this.state.textIdx % textArray.length];

    return (
      <div className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.gmcont}`}>
        <div
          className={`${styles.text_line} ${theme.giant} ${theme.gds3} ${theme.gde12} ${theme.gmmain}`}
        >
          We create
        </div>
        <div
          className={`${styles.text_line} ${styles.switchText} ${styles.headline} ${theme.gdmain} ${theme.gmmain}`}
        >
          <span className={`${theme.giant}`}>{textThatChanges}</span>
        </div>

        <div
          className={`${styles.text_line} ${theme.giant} ${theme.gds6} ${theme.gde12} ${theme.gmmain}`}
        >
          brands
        </div>
        <div className={`${styles.copy} ${theme.gds1} ${theme.gde4} ${theme.gmmain}`}>
          <p>{this.props.copy}</p>
          <Link className={`${theme.h5_founders} ${theme.text_link} ${theme.mT8} `} to="/about">
            About Us
          </Link>
        </div>
      </div>
    );
  }
}

export default Header;
