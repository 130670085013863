import { Link } from "gatsby";
import React, { Component } from "react";
import Asset from "./asset";
import styles from "./asof-intro.module.scss";
import theme from "../styles/theme.module.scss";
import logo from "../images/asof_circle.svg";

function HomeAsof(props) {
  return (
    <div className={`${styles.root} ${theme.borderBottom}`}>
      <h1
        className={`${theme.h2_founders}  ${theme.gdcont} ${theme.gmcont} ${theme.mT6} ${theme.mB6}`}
      >
        <span className={`${theme.gds6} ${theme.gde6} ${theme.gmmain}`}>A</span>
        <span className={`${theme.gds3} ${theme.gde7} ${theme.gmmain}`}>State of</span>
        <span className={`${theme.gds7} ${theme.gd10} ${theme.gmmain}`}>Feeling</span>
      </h1>

      <div className={`${theme.gdcont} ${theme.gmcont} ${theme.mB6}`}>
        <div className={`${styles.introCopy} ${theme.gds1} ${theme.gde4} ${theme.gmmain}`}>
          <p className={``}>{props.copy}</p>
        </div>

        <Link
          className={`${theme.gds11} ${theme.gde12} ${styles.asofLogo} ${theme.gmmain}`}
          to={`/asof/`}
        >
          <img src={logo} alt="ASOF Logo" width="168px" height="168px" />
        </Link>
      </div>
    </div>
  );
}

export default HomeAsof;
