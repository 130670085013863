import React, { useEffect, useState } from 'react';
import Icon from './icon';
import styles from './home-overlay.module.scss';
import theme from '../styles/theme.module.scss';
import { isServerSide } from '../utils/generalFun';

function Overlay() {
  const [toggled, setToggled] = useState(!isServerSide() && window.homeOverlayMountedOnce);

  useEffect(() => {
    if (window.homeOverlayMountedOnce) return;

    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      window.homeOverlayMountedOnce = true;
      document.body.style.overflow = 'visible';
      setToggled(true);
    });
  }, []);

  return (
    <div className={[styles.root, toggled ? styles.toggled : ''].join(' ')}>
      <div
        className={[
          styles.inner,
          theme.fdcenter,
          theme.fdcont,
          theme.fmcenter,
          theme.fmcont,
        ].join(' ')}
      >
        <Icon symbol='logo' />
      </div>
    </div>
  );
}

export default Overlay;
