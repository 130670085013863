import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, arrayDiffByKey } from "../lib/helpers";
import ProjectsGrid from "../components/work-projects-grid";
import CategoryList from "../components/work-category-list";

export const query = graphql`
  query WorkPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      socialImage {
        asset {
          url
        }
      }
    }
    categories: allSanityCategory(
      sort: { order: ASC, fields: order }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    category: sanityCategory(slug: { current: { eq: "all" } }) {
      id
      title
      projectLink {
        ...SanityProjectLinkFragment
      }
    }
    projects: allSanityProject(
      sort: { order: ASC, fields: order }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          ...SanityProjectFragment
        }
      }
    }
  }
`;

const WorkPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories).filter(filterOutDocsWithoutSlugs);
  const allProjectNodes = data && data.category.projectLink;
  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs);
  const filterProjectNodes = arrayDiffByKey("id", projectNodes, allProjectNodes);
  const nodes = allProjectNodes.concat(filterProjectNodes);

  return (
    <Layout>
      <SEO title="Work" ogImage={data?.site?.socialImage?.asset?.url}/>
      <Container>
        <h1 hidden>Projects</h1>
        {categoryNodes && categoryNodes.length > 0 && <CategoryList nodes={categoryNodes} />}
        {projectNodes && projectNodes.length > 0 && <ProjectsGrid nodes={nodes} />}
      </Container>
    </Layout>
  );
};

export default WorkPage;
