import { Link } from "gatsby";
import React, { useRef } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Project from "./work-projects-grid-project";
import styles from "./work-projects-grid.module.scss";
import theme from "../styles/theme.module.scss";
import { navigate } from "gatsby";

function CategoryList(props) {
  const breakpoints = useBreakpoint();
  const activeCategoryTitle = props.activeCat ? props.activeCat.title : "All";
  const isActive = (className) => ({ isCurrent }) => {
    const activeClassName = { className: `${styles.active} active` };
    if (isCurrent) {
      return activeClassName;
    }
  };

  const count = props.nodes.length;
  const selectRef = useRef(null);

  const handleClick = () => {
    selectRef.current.size = count;
  };

  if (breakpoints.sm) {
    return (
      <div className={`${styles.categoriesMobile} ${theme.mB12} ${theme.borderTop} ${theme.pT10}`}>
        <label className={`${styles.label} ${theme.h5_founders} ${theme.mB8}`} for="categories">
          <a onClick={handleClick}>
            Showing <span className={`${styles.current}`}>{activeCategoryTitle}</span>
          </a>
        </label>
        <select
          className={`${styles.select} ${theme.h5_founders}`}
          id="categories"
          name="categories"
          ref={selectRef}
          onChange={(event) => {
            // TODO: do something with form values
            navigate(event.target.value);
          }}
        >
          <option value="/work" selected className={`${styles.option} ${styles.active}`}>
            {activeCategoryTitle}
          </option>
          {props.nodes &&
            props.nodes.map((node, index) => {
              if (activeCategoryTitle !== node.title) {
                return (
                  <option
                    key={index}
                    className={`${styles.option}`}
                    value={`/work/${node.slug.current == "all" ? "" : node.slug.current}`}
                  >
                    {node.title}
                  </option>
                );
              }
            })}
        </select>
      </div>
    );
  }
  if (!breakpoints.sm) {
    return (
      <ul className={`${styles.categories} ${theme.mB4} ${theme.mT11}`}>
        {props.nodes &&
          props.nodes.map((node, index) => (
            <li key={index}>
              <Link
                className={
                  node.slug.current == "all" && activeCategoryTitle == "All"
                    ? `${styles.active}`
                    : ""
                }
                getProps={isActive()}
                to={`/work/${node.slug.current == "all" ? "" : node.slug.current}`}
              >
                {node.title}
              </Link>
            </li>
          ))}
      </ul>
    );
  }
}

export default CategoryList;
