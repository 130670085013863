import React from "react";
import Project from "./work-projects-grid-project";
import styles from "./work-projects-grid.module.scss";
import theme from "../styles/theme.module.scss";

function ProjectsGrid(props) {
  let counter = 0;
  let heroStatus = false;
  let heroCounter = 0;

  function renderCounter() {
    counter++;
    if (counter === 12) {
      counter = 1;
    }

    if (counter === 1 || counter === 2) {
      heroStatus = true;
      if (heroCounter === 5) {
        heroCounter = 0;
      }
      heroCounter++;
    } else {
      heroStatus = false;
    }
    return [counter, heroStatus, heroCounter];
  }

  return (
    <div className={`${styles.root}`}>
      <ul className={`${styles.list} ${theme.fdcont} ${theme.mB5}`}>
        {props.nodes &&
          props.nodes.map((node, index) => (
            <Project key={index} {...node} counter={renderCounter()} />
          ))}
      </ul>
    </div>
  );
}

export default ProjectsGrid;
