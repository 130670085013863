import { Link } from 'gatsby';
import React from 'react';
import Asset from './asset';
import styles from './work-projects-grid.module.scss';
import theme from '../styles/theme.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

function Project(props) {
  const breakpoints = useBreakpoint();

  const counter = props.counter[0];
  const heroStatus = props.counter[1];
  const heroCounter = props.counter[2];

  const heightZero = 480;
  const widthZero = 600;
  const heightHero = 800;
  const widthHero = 1000;

  const countClass = `nth${heroCounter}`;

  const heroThumb = props.thumbnailHeroAsset[0]
    ? props.thumbnailHeroAsset[0]
    : props.thumbnailAsset[0];

  let align = '';

  if (counter === 1 || counter === 3 || counter === 6 || counter === 9) {
    align = 'flex-start';
  } else if (counter === 4 || counter === 7 || counter === 10) {
    align = 'center';
  } else {
    align = 'flex-end';
  }
  if (breakpoints.md) {
    return (
      <li
        key={props.id}
        className={`${styles.project} ${theme.mB5}  ${heroStatus ? styles.hero : styles.zero} ${
          heroStatus ? styles[countClass] : ''
        }`}>
        <Link className={[styles.project_link].join(' ')} to={`/work/${props.slug.current}`}>
          <div className={[styles.project_assetwrapper].join(' ')}>
            {!heroStatus && (
              <>
                <Asset
                  asset={props.thumbnailAsset[0]}
                  height={heightZero}
                  width={widthZero}
                  className={styles.entryAsset}
                />
              </>
            )}
            {!breakpoints.md && !heroStatus && (
              <>
                <div className={styles.project_hover}>
                  <Asset
                    asset={props.thumbnailHoverAsset[0]}
                    height={heightZero}
                    width={widthZero}
                    className={styles.entryAsset}
                  />
                </div>
              </>
            )}
            {heroStatus && (
              <>
                <div className={styles.project_asset}>
                  <Asset
                    asset={heroThumb}
                    height={heightHero}
                    width={widthHero}
                    className={styles.entryAsset}
                  />
                </div>
              </>
            )}
            {!breakpoints.md && heroStatus && (
              <>
                <div className={styles.project_hover}>
                  <div className={styles.project_hover_inner}>
                    <p className={[styles.project_copy, theme.h3_tiempos].join(' ')}>
                      {props.tagline}
                    </p>
                    {props.cta && (
                      <>
                        <p className={[theme.mT8, theme.h5_founders].join(' ')}>{props.cta}</p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={styles.project_meta}>
            <h2 className={[styles.project_title, theme.h5_founders].join(' ')}>
              <Link to={`/work/${props.slug.current}`}>{props.title}</Link>
            </h2>
            <ul className={styles.project_cat}>
              {props.tags && (
                <>
                  {props.tags.map(function(cat, index) {
                    return <li key={index}>{cat.value.trim()}</li>;
                  })}
                </>
              )}
              {props.tags.length == 0 && (
                <>
                  {props.categories.map(function(cat, index) {
                    return <li key={index}>{cat.title}</li>;
                  })}
                </>
              )}
            </ul>
          </div>
        </Link>
      </li>
    );
  }

  return (
    <div
      style={{
        width: heroStatus ? '50%' : '33.33%',
        display: 'flex',
        justifyContent: align,
      }}>
      <li
        key={props.id}
        className={`${styles.project} ${theme.mB5}  ${heroStatus ? styles.hero : styles.zero} ${
          heroStatus ? styles[countClass] : ''
        }`}>
        <Link className={[styles.project_link].join(' ')} to={`/work/${props.slug.current}`}>
          <div className={[styles.project_assetwrapper].join(' ')}>
            {!heroStatus && (
              <>
                <Asset
                  asset={props.thumbnailAsset[0]}
                  height={heightZero}
                  width={widthZero}
                  className={styles.entryAsset}
                />
              </>
            )}
            {!breakpoints.md && !heroStatus && (
              <>
                <div className={styles.project_hover}>
                  <Asset
                    asset={props.thumbnailHoverAsset[0]}
                    height={heightZero}
                    width={widthZero}
                    className={styles.entryAsset}
                  />
                </div>
              </>
            )}
            {heroStatus && (
              <>
                <div className={styles.project_asset}>
                  <Asset
                    asset={heroThumb}
                    height={heightHero}
                    width={widthHero}
                    className={styles.entryAsset}
                  />
                </div>
              </>
            )}
            {!breakpoints.md && heroStatus && (
              <>
                <div className={styles.project_hover}>
                  <div className={styles.project_hover_inner}>
                    <p className={[styles.project_copy, theme.h3_tiempos].join(' ')}>
                      {props.tagline}
                    </p>
                    {props.cta && (
                      <>
                        <p className={[theme.mT8, theme.h5_founders].join(' ')}>{props.cta}</p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={styles.project_meta}>
            <h2 className={[styles.project_title, theme.h5_founders].join(' ')}>
              <Link to={`/work/${props.slug.current}`}>{props.title}</Link>
            </h2>
            <ul className={styles.project_cat}>
              {props.tags && (
                <>
                  {props.tags.map(function(cat, index) {
                    return <li key={index}>{cat.value.trim()}</li>;
                  })}
                </>
              )}
              {props.tags.length == 0 && (
                <>
                  {props.categories.map(function(cat, index) {
                    return <li key={index}>{cat.title}</li>;
                  })}
                </>
              )}
            </ul>
          </div>
        </Link>
      </li>
    </div>
  );
}

export default Project;
