import React from "react";
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import PreviewTemplate from '../components/preview-template'

const PreviewPage = (props) => {
  const token = process.env.GATSBY_SANITY_READ_TOKEN

  let data = useStaticQuery(graphql`
    query homepagePreview {
      site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        ...SanitySiteSettingsFragment
        mentorshipFormToggle
        socialImage {
          asset {
            url
          }
        }
      }
      homepage: sanityHomePage {
        ...SanityHomeFragment
      }
      about: sanityAbout {
        ...SanityAboutFragment
      }
      contact: sanityContact {
        ...SanityContactFragment
      }
      categories: allSanityCategory(
        sort: { order: ASC, fields: order }
        filter: { featured: { eq: true } }
      ) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      category: sanityCategory(slug: { current: { eq: "all" } }) {
        id
        title
        projectLink {
          ...SanityProjectLinkFragment
        }
      }
      projects: allSanityProject(
        sort: { order: ASC, fields: order }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            ...SanityProjectFragment
          }
        }
      }
      allProjects: allSanityProject {
        edges {
          node {
            ...SanityProjectFragment
            seoTitle
            seoDescription
            seoKeywords
            socialImage {
              asset {
                url
              }
            }
          }
        }
      }
      upcoming: allSanityAsofEntry(filter: { isFuture: { eq: true } }) {
        edges {
          node {
            ...SanityAsofLinkFragment
          }
        }
      }
      past: allSanityAsofEntry(filter: { isFuture: { eq: false } }) {
        edges {
          node {
            ...SanityAsofLinkFragment
          }
        }
      }
      allAsofEntries: allSanityAsofEntry {
        edges {
          node {
            ...SanityAsofEntryFragment
          }
        }
      }
      philosophy: sanityPhilosophy {
        ...SanityPhilosophyFragment
      },
      sanityOffTwoMinds: sanityOffTwoMinds {
        heroSection {
          heroBG {
            asset {
              url
            }
          }
          heroLogoD
          heroLogoM
          heroLogoT
        }
        description
        pricingSection {
          title
          qa {
            title
            description
          }
        }
        productsSection {
          title
          detail
          products {
            title
            soldOut
            detail
            images {
              asset {
                url
              }
            }
            price
            pricePlaceholder
            processingFee
          }
        }
        policySection {
          title
          qa {
            title
            description
          }
        }
        footer {
          link {
            title
            url
          }
          createdBy
          createdByMobile
          createdByLogos {
            logo
            url
          }
          createdByLogosM {
            logo
            url
          }
          footerLogoD
          footerLogoT
          footerLogoM
        }
      }
    }
  `)

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PreviewTemplate location={props.location} data={data} token={token} />
    </div>
  );
};

export default PreviewPage;
