import { Link } from "gatsby";
import React, { Component } from "react";
import Asset from "./asset";
import styles from "./home-asof.module.scss";
import theme from "../styles/theme.module.scss";
import logo from "../images/asof_circle.svg";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function HomeAsof(props) {
  const node = props.node;
  return (
    <div className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.borderTop}`}>
      <div className={`${styles.wrapper} ${theme.gdfull} ${theme.gmmain}`}>
        <div className={`${styles.intro} ${theme.fdcont} ${theme.fdcolumn}`}>
          <Link to={`/asof/`}>
            <h2 className={theme.h2_founders}>
              <span>A</span>
              <span>State</span>
              <span>of</span>
              <span>Feeling</span>
            </h2>
          </Link>
          <Link className={`${styles.asofLogo}`} to={`/asof/`}>
            <img src={logo} alt="ASOF Logo" width="168px" height="168px" />
          </Link>
          <p className={styles.introCopy}>{props.copy}</p>
        </div>
        {node.isFuture && (
          <div className={`${styles.entry} ${styles.entryUpcoming}`}>
            <div
              className={`${theme.fdcont} ${theme.fmcont} ${theme.borderBottom} ${theme.mB12} ${theme.pB12}`}
            >
              <span className={`${styles.label} ${theme.h5_founders}`}>Upcoming</span>
              <span>{node.date}</span>
            </div>

            <h3
              className={`${theme.h5_founders} ${theme.borderBottom} ${theme.mB12} ${theme.pB12}`}
            >
              {node.title}
            </h3>

            <div>{node.previewCopy}</div>
            {node.url && (
              <>
                <div className={`${theme.pT9}`}>
                  <a className={`${theme.h5_founders} ${theme.text_link}`} href={node.url}>
                    Sign Up
                  </a>
                </div>
              </>
            )}

            <div className={`${styles.imagesWrapper} ${theme.pT7}`}>
              {node.previewImages &&
                node.previewImages.map((node, index) => (
                  <div key={index} className={`${styles.image} ${theme.fd2} `}>
                    <Asset asset={node} maxWidth="800" />
                  </div>
                ))}
            </div>
          </div>
        )}
        {!node.isFuture && (
          <div className={`${styles.entry} ${styles.entryPast} ${theme.fdcont} ${theme.fdcolumn}`}>
            <Link className={styles.titleWrapper} href={`/asof/${node.slug.current}`}>
              <h3
                className={`${theme.h5_founders} ${theme.borderBottom} ${theme.mB12} ${theme.pB12}`}
              >
                {node.title}
              </h3>
            </Link>
            <div>{node.previewCopy}</div>
            {node.url && (
              <>
                <a className={`${theme.h5_founders}`} href={node.url}>
                  Sign Up
                </a>
              </>
            )}

            <div className={styles.videoWrapper}>
              <Link className={styles.videoLink} href={`/asof/${node.slug.current}`}>
                <Asset
                  playMode="hover"
                  asset={node.previewVideo[0]}
                  className={`${theme.border}`}
                />
                <span className={`${theme.text_link} ${styles.videoCaption} ${theme.h5_founders}`}>
                  Watch the video
                </span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeAsof;
